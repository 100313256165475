import { useRouter } from 'next/router';
import { useEffect } from 'react';
import logger from '@finance/shared/dist/utils/logger';

function OnRouteChangeStart(
    url: string,
    options: {
        shallow: boolean;
    }
) {
    logger.debug({ url, options }, 'OnRouteChangeStart');
}

function OnRouteChangeComplete(
    url: string,
    options: {
        shallow: boolean;
    }
) {
    logger.debug({ url, options }, 'OnRouteChangeComplete');
}

export default function RouteListener(): null {
    const router = useRouter();

    useEffect(() => {
        router.events.on('routeChangeStart', OnRouteChangeStart);
        router.events.on('routeChangeComplete', OnRouteChangeComplete);

        // unsubscribe from events in useEffect return function
        return () => {
            router.events.off('routeChangeStart', OnRouteChangeStart);
            router.events.off('routeChangeComplete', OnRouteChangeComplete);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
