import { FullStory as FullStoryClient } from '@fullstory/browser';
import { IAnalyticsEventProperties, AnalyticsEventTypes } from 'lib/client/services/mixpanel/useAnalytics';
import logger from '@finance/shared/dist/utils/logger';

export default function useFullStory() {
    const trackFullstoryEvent = ({
        event,
        type,
        props = {}
    }: {
        event: string;
        type: AnalyticsEventTypes;
        props?: IAnalyticsEventProperties;
    }) => {
        const name = type || 'action';
        const description = event;
        const properties = { ...props, description };
        logger.debug({ name, description, props }, '[tracking: fullStory]');
        FullStoryClient('trackEvent', {
            name,
            properties
        });
    };
    return {
        trackFullstoryEvent
    };
}
