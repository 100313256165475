import appConfig from 'config/appConfig';
import { logger } from '@finance/shared/dist/utils/logger';
import { datadogRum } from '@datadog/browser-rum';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function stringify(obj: any) {
    try {
        return JSON.stringify(obj);
    } catch (error) {
        return `unable to stringify: '${error}'`;
    }
}

function reportToDataDog(
    error: Error | string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context: { [key: string]: any }
) {
    if (!datadogRum || !datadogRum.addError) {
        return;
    }
    datadogRum.addError(error, context);
}

interface ErrorProps {
    error: Error;
    message?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context?: { [key: string]: any };
}

export function trackError(errorProps: ErrorProps) {
    const { error, message, context = {} } = errorProps;

    let errorToPass;
    let contextToPass;

    if (error instanceof Error) {
        errorToPass = message
            ? new Error(message, {
                  cause: error
              })
            : error;
        contextToPass = context;
    } else {
        errorToPass = message ? new Error(message) : error;
        contextToPass = message ? { ...context, cause: stringify(error) } : context;
    }

    reportToDataDog(errorToPass, contextToPass);

    if (!appConfig.isProductionEnv) {
        logger.error({ ...contextToPass, error }, message);
    }
}
