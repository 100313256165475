import { cloneDeep } from '@apollo/client/utilities';
import { State } from '@unit-finance/unit-node-sdk';
import { useState } from 'react';
import { fieldLengths } from 'lib/form-validation-service';
import { EntityType, LocalStorageKeys } from 'lib/types/types';
import { digitsOnly } from 'lib/utils';
import { EntityType as UnitEntityType, OfficerTitle } from '__generated__/graphql';
import {
    IFormPage,
    IFormValues,
    IIntialPageValues,
    ILast4digits,
    inputTypes,
    ISubmitFormValues,
    PrefillInfo,
    TInput,
    TRepeatableSectionValues,
    TSectionItem
} from 'components/application-form/form-pages/form-types';
import { CustomerTypeEnum } from '@finance/platform/dist/types/enums';
import { KycBeneficialOwner, KycBusinessInfo, KycFormValues } from 'components/onboarding/kyc/types';

export type TSetField = (props: { field: string; value: string }) => void;

export type TSetRepeatSectionValue = (props: { idx: number; field: string; value: string; sectionKey: string }) => void;

export type TAddRepeatSection = (props: { sectionKey: string }) => void;

export type TRemoveSection = (props: { sectionIdx: number; sectionKey: string }) => void;

interface IUseStateProps {
    prefill?: IFormValues;
}

export const useFormState = ({ prefill }: IUseStateProps) => {
    const { values, last4 } = prefillInfo(prefill);
    const [last4Digits] = useState(last4);
    const [formValues, setFormValues] = useState<IFormValues>(values);
    const [repeatableSections, setRepeatableSections] = useState<TRepeatableSectionValues>({ beneficialOwners: [{}] });

    const setField: TSetField = ({ field, value }) => {
        const newValues = { ...formValues };
        newValues[field] = value;
        setFormValues(newValues);
    };

    const addRepeatableSection: TAddRepeatSection = ({ sectionKey }: { sectionKey: string }) => {
        const newRepeatSections = cloneDeep(repeatableSections);
        newRepeatSections[sectionKey].push({});
        setRepeatableSections(newRepeatSections);
    };

    const setRepeatSectionValue: TSetRepeatSectionValue = ({ idx, field, value, sectionKey }) => {
        const newRepeatSections = cloneDeep(repeatableSections);
        newRepeatSections[sectionKey][idx][field] = value;
        setRepeatableSections(newRepeatSections);
    };

    const removeSection: TRemoveSection = ({ sectionIdx, sectionKey }) => {
        const newSections = cloneDeep(repeatableSections);
        newSections[sectionKey] = newSections[sectionKey].filter((curr, idx) => idx !== sectionIdx);
        setRepeatableSections(newSections);
    };

    return {
        formValues,
        last4Digits,
        setField,
        repeatableSections,
        addRepeatableSection,
        setRepeatSectionValue,
        removeSection,
        setFormValues,
        setRepeatableSections
    };
};

export const buildInitialValues = ({
    page,
    values,
    repeatableSectionValues
}: {
    page: IFormPage;
    values: IFormValues;
    repeatableSectionValues: TRepeatableSectionValues;
}) => {
    const res: IIntialPageValues = {};
    page.sections.forEach(section =>
        section.items.forEach(item => {
            if (isInput(item)) {
                const key = (item as TInput).field;
                res[key] = values[key];
            }
        })
    );
    if (page.repeatableSection) {
        const { sectionKey, section } = page.repeatableSection;
        res[sectionKey] = repeatableSectionValues[sectionKey].map(sectionState =>
            section.items
                .filter(item => isInput(item))
                .reduce((acc, input) => {
                    const { field } = input as TInput;
                    (acc as unknown as IFormValues)[field] = sectionState[field] || '';
                    return acc;
                }, {})
        );
    }

    if (page.checkbox) {
        const key = page.checkbox.text;
        res[key] = values[key] || false;
    }

    return res;
};

export const isInput = (item: TSectionItem) => inputTypes.some(type => type === item.type);

export const getPageProps = ({ formValues, last4Digits }: { formValues: IFormValues; last4Digits: ILast4digits }) => {
    const applicationType =
        formValues.entityType && formValues.entityType !== EntityType.SoleProprietor
            ? CustomerTypeEnum.Business
            : CustomerTypeEnum.Individual;

    const needBeneficialOwners = formValues.beneficialOwner === 'No';

    const { accountLast4, routingLast4 } = last4Digits;

    return {
        applicationType,
        needBeneficialOwners,
        accountLast4,
        routingLast4
    };
};

export function normalizeApplicationDate(dateString: string) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
}

function getDateString(date: Date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const day = String(date.getUTCDay()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export interface IValuesWithTypes extends ISubmitFormValues {
    entityType: EntityType;
    state: State;
}

export function createBusinessApplicationAttributes({ values }: { values: KycFormValues }) {
    const { beneficialOwners, businessInfo, ownerInfo, entityType } = values;
    const {
        name,
        dba,
        phoneNumber,
        stateOfIncorporation,
        yearOfIncorporation,
        ein,
        streetAddress: street,
        city,
        state,
        zipcode: postalCode,
        officerTitle
    } = businessInfo as KycBusinessInfo;
    const {
        firstName,
        lastName,
        dateOfBirth,
        ssn,
        email,
        phoneNumber: ownerPhoneNumber,
        streetAddress: ownerStreet,
        city: ownerCity,
        state: ownerState,
        zipcode: ownerPostalCode,
        website,
        businessVertical
    } = ownerInfo;
    return {
        entityType: entityType as unknown as UnitEntityType,
        name,
        dba: dba && dba.length > 0 ? dba : undefined,
        address: {
            street,
            city,
            state: state,
            postalCode,
            country: 'US'
        },
        phone: {
            countryCode: '1',
            number: digitsOnly(phoneNumber)
        },
        stateOfIncorporation,
        ein,
        contact: {
            fullName: {
                first: firstName,
                last: lastName
            },
            email: email,
            phone: {
                countryCode: '1',
                number: digitsOnly(ownerPhoneNumber)
            }
        },
        officer: {
            fullName: {
                first: firstName,
                last: lastName
            },
            dateOfBirth: getDateString(dateOfBirth as Date),
            title: officerTitle,
            ssn: ssn,
            email: email,
            phone: {
                countryCode: '1',
                number: digitsOnly(ownerPhoneNumber)
            },
            address: {
                street: ownerStreet,
                city: ownerCity,
                state: ownerState,
                postalCode: ownerPostalCode,
                country: 'US'
            }
        },
        beneficialOwners: beneficialOwners?.map(owner => createBeneficialOwnerAttributes(owner)),
        website: website,
        businessVertical,
        yearOfIncorporation
    };
}

function createBeneficialOwnerAttributes(owner: KycBeneficialOwner) {
    const {
        firstName,
        lastName,
        dateOfBirth,
        ssn,
        email,
        percentage,
        phoneNumber,
        streetAddress: street,
        city,
        state,
        zipcode: postalCode
    } = owner;
    return {
        fullName: {
            first: firstName,
            last: lastName
        },
        dateOfBirth: getDateString(dateOfBirth as Date),
        ssn,
        email,
        percentage: Number(percentage),
        phone: {
            countryCode: '1',
            number: digitsOnly(phoneNumber)
        },
        address: {
            street,
            city,
            state,
            postalCode,
            country: 'US'
        }
    };
}

export function createIndividualApplicationAttributes({ values }: { values: KycFormValues }) {
    const {
        ownerInfo: {
            ssn,
            firstName,
            lastName,
            streetAddress: street,
            city,
            state,
            zipcode: postalCode,
            email,
            phoneNumber,
            website,
            businessVertical,
            dateOfBirth
        }
    } = values;

    return {
        ssn,
        fullName: {
            first: firstName,
            last: lastName
        },
        dateOfBirth: getDateString(dateOfBirth as Date),
        address: {
            street,
            city,
            state,
            postalCode,
            country: 'US'
        },
        email,
        phone: {
            countryCode: '1',
            number: digitsOnly(phoneNumber)
        },
        soleProprietorship: true,
        website,
        businessVertical
    };
}

export function createBusinessApplicationData({
    formValues,
    beneficialOwners
}: {
    formValues: IValuesWithTypes;
    beneficialOwners: ISubmitFormValues[];
}) {
    const title = formValues.officer as OfficerTitle;
    const normalizedOwners = beneficialOwners?.map(owner => ({
        fullName: {
            first: owner.beneficialOwnerFirstName,
            last: owner.beneficialOwnerLastName
        },
        dateOfBirth: normalizeApplicationDate(owner.beneficialOwnerBirthDate),
        ssn: owner.beneficialOwnerSsn,
        email: owner.beneficialOwnerEmail,
        percentage: Number(owner.ownershipFraction),
        phone: {
            countryCode: '1',
            number: digitsOnly(owner.beneficialOwnerPhoneNumber)
        },
        address: {
            street: owner.beneficialOwnerAddress,
            city: owner.beneficialOwnerCity,
            state: owner.beneficialOwnerState,
            postalCode: owner.beneficialOwnerZipCode,
            country: 'US'
        }
    }));

    return {
        name: formValues.legalName,
        dba: formValues.aliasName,
        address: {
            street: formValues.address,
            city: formValues.city,
            state: formValues.state,
            postalCode: formValues.zipCode,
            country: 'US'
        },
        phone: {
            countryCode: '1',
            number: digitsOnly(formValues.phoneNumber)
        },
        stateOfIncorporation: formValues.incorpState,
        ein: formValues.taxId,
        entityType: formValues.entityType as unknown as UnitEntityType,
        contact: {
            fullName: {
                first: formValues.firstName,
                last: formValues.lastName
            },
            email: formValues.email,
            phone: {
                countryCode: '1',
                number: digitsOnly(formValues.phoneNumber)
            }
        },
        officer: {
            fullName: {
                first: formValues.firstName,
                last: formValues.lastName
            },
            dateOfBirth: normalizeApplicationDate(formValues.birthDate),
            title,
            ssn: formValues.ssn,
            email: formValues.email,
            phone: {
                countryCode: '1',
                number: digitsOnly(formValues.phoneNumber)
            },
            address: {
                street: formValues.address,
                city: formValues.city,
                state: formValues.state,
                postalCode: formValues.zipCode,
                country: 'US'
            }
        },
        beneficialOwners: normalizedOwners,
        website: formValues.website,
        businessVertical: formValues.businessVertical,
        yearOfIncorporation: formValues.yearOfIncorporation
    };
}

export function createIndividualApplicationData({ formValues }: { formValues: IValuesWithTypes }) {
    const {
        ssn,
        firstName,
        lastName,
        address: street,
        city,
        state,
        zipCode: postalCode,
        email,
        phoneNumber,
        website,
        businessVertical
    } = formValues;
    return {
        ssn,
        fullName: {
            first: firstName,
            last: lastName
        },
        dateOfBirth: normalizeApplicationDate(formValues.birthDate),
        address: {
            street,
            city,
            state,
            postalCode,
            country: 'US'
        },
        email,
        phone: {
            countryCode: '1',
            number: digitsOnly(phoneNumber)
        },
        soleProprietorship: true,
        website,
        businessVertical
    };
}

export function formatInput({ inputType, value }: { inputType?: string; value: string }) {
    const limitedLengthDigitsOnly = (length: number) => {
        return digitsOnly(value).substring(0, length);
    };

    if (inputType?.startsWith('range')) {
        return limitedLengthDigitsOnly(3);
    }

    switch (inputType) {
        case 'routing_number':
        case 'ssn':
            return limitedLengthDigitsOnly(fieldLengths.routing_ssn.length);

        case 'zip_code':
            return limitedLengthDigitsOnly(fieldLengths.zip_code.length);

        case 'bank_account_number':
            return limitedLengthDigitsOnly(fieldLengths.bank_account_number.max);

        case 'otp':
            return limitedLengthDigitsOnly(fieldLengths.otp.length);

        case 'long_date':
            const longDateDigits = digitsOnly(value);
            let mm = longDateDigits.substring(0, 2);
            let dd = longDateDigits.substring(2, 4);
            const yyyy = longDateDigits.substring(4, 8);
            if (dd) {
                mm += '/';
            }
            if (yyyy) {
                dd += '/';
            }
            return `${mm}${dd}${yyyy}`;

        case 'short_date':
            const shortDateDigits = digitsOnly(value);
            let MM = shortDateDigits.substring(0, 2);
            const YYYY = shortDateDigits.substring(2, 6);
            if (YYYY) {
                MM += '/';
            }
            return `${MM}${YYYY}`;

        case 'phone_number':
            const cleanNumber = digitsOnly(value);
            let areaCode = cleanNumber.substring(0, 3);
            let phoneFirstPart = cleanNumber.substring(3, 6);
            const phoneSecondPart = cleanNumber.substring(6, 10);
            if (phoneFirstPart) {
                areaCode = `(${areaCode}) `;
            }
            if (phoneSecondPart) {
                phoneFirstPart += '-';
            }
            return `${areaCode}${phoneFirstPart}${phoneSecondPart}`;

        default:
            return value;
    }
}

export function prefillInfo(prefill: IFormValues | undefined): PrefillInfo {
    if (!prefill) {
        return {
            values: {},
            last4: {}
        };
    }

    return {
        values: {
            firstName: prefill.first_name || '',
            lastName: prefill.last_name || '',
            email: prefill.email || '',
            phoneNumber: prefill.phone_number || '',
            address: prefill.address || '',
            city: prefill.city || '',
            state: prefill.state || '',
            zipCode: prefill.zip_code || '',
            website: prefill.website_url || '',

            businessEmail: prefill.business_email || '',
            businessPhone: prefill.business_phone_number || '',
            businessAddress: prefill.business_address || '',
            businessCity: prefill.business_city || '',
            businessState: prefill.business_state || '',
            businessZipCode: prefill.business_zip_code || '',
            incorpState: prefill.business_stat || '',
            businessVertical: prefill.business_vertical || ''
        },
        last4: {
            accountLast4: (prefill.account_number_last4 as string) || '',
            routingLast4: (prefill.routing_number_last4 as string) || ''
        }
    };
}

export function saveKYCApplicationStatus(values: { formValues: IFormValues; repeatableSections: TRepeatableSectionValues }) {
    localStorage.setItem(LocalStorageKeys.APPLICATION, JSON.stringify(values));
}

export function loadKYCApplicationStatus() {
    const savedApplication = localStorage.getItem(LocalStorageKeys.APPLICATION);
    return savedApplication && JSON.parse(savedApplication);
}

export function deleteKYCApplicationStatus() {
    localStorage.removeItem(LocalStorageKeys.APPLICATION);
}
