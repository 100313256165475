import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import getConfig from 'next/config';
import { useSession } from 'next-auth/react';
import logger from '@finance/shared/dist/utils/logger';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { IAnalyticsEventProperties } from 'lib/client/services/mixpanel/useAnalytics';
import { trackError } from 'lib/error.utils';
import { useRouter } from 'next/router';

let instance: AnalyticsBrowser | null;

const getSegmentClient = () => {
    if (!instance) {
        instance = new AnalyticsBrowser();
    }
    return instance;
};

interface IContextProps {
    track: (event: string, properties?: IAnalyticsEventProperties) => void;
}

export const SegmentContext = createContext<IContextProps>({} as IContextProps);
SegmentContext.displayName = 'SegmentContext';

export const useSegment = () => {
    return useContext(SegmentContext);
};

const SegmentProvider = ({ children }: { children: ReactNode }) => {
    const {
        publicRuntimeConfig: { segmentClientWriteKey, env }
    } = getConfig();
    const session = useSession();
    const segment = getSegmentClient();

    const [isLoaded, setIsLoaded] = useState(false);
    const [didIdentify, setDidIdentify] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            segment.page();
        };
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    });

    useEffect(() => {
        if (!isLoaded && segmentClientWriteKey) {
            segment.load({
                writeKey: segmentClientWriteKey
            });
            setIsLoaded(true);
            logger.info({ env }, 'segment loaded');
        } else if (!segmentClientWriteKey) {
            logger.warn('missing segment client write key');
        }
    }, [segment, isLoaded, segmentClientWriteKey, env]);

    useEffect(() => {
        if (isLoaded && !didIdentify && session.data) {
            const {
                user: { _id: userId, email, accountId },
                customerId: unitCustomerId
            } = session.data;
            segment.identify(userId, { unitCustomerId, email, account_id: accountId });
            setDidIdentify(true);
            segment.on('error', error => {
                const message = 'Segment error';
                const context = { error, userId, email };
                logger.error(context, 'Segment error');
                trackError({
                    error,
                    message,
                    context
                });
            });
            logger.debug({ session }, 'identified segment user');
        }
    }, [segment, isLoaded, didIdentify, session]);

    function enrichProperties(properties?: IAnalyticsEventProperties) {
        const user = session.data?.user;
        return {
            ...properties,
            account_id: user?.accountId,
            statsigCustomIDs: ['account_id', user?.accountId]
        };
    }

    function trackEvent(event: string, properties?: IAnalyticsEventProperties) {
        logger.debug({ event, properties }, 'SEGMENT EVENT');
        segment.track({
            type: 'track',
            event,
            properties: enrichProperties(properties)
        });
    }

    const value = {
        track: trackEvent
    };

    return <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>;
};

export default SegmentProvider;
