import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { useEffect } from 'react';

export default function DatadogRumInit() {
    useEffect(() => {
        const {
            publicRuntimeConfig: { ddClientToken: clientToken, ddApplicationId: applicationId }
        } = getConfig();
        if (applicationId && clientToken) {
            datadogRum.init({
                applicationId,
                clientToken,
                site: 'datadoghq.com',
                service: 'hb-finance-http-client',
                env: 'prod',
                version: '1.0.0',
                sampleRate: 100,
                premiumSampleRate: 100,
                trackInteractions: true,
                silentMultipleInit: true,
                defaultPrivacyLevel: 'mask-user-input'
            });

            datadogRum.startSessionReplayRecording();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}
