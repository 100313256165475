import { Chart as ChartJS } from 'chart.js';
import { colors } from '@honeybook/hbui';

type TChart = typeof ChartJS;
export const chartBase = (Chart: TChart) => {
    Chart.defaults.font = {
        size: 14,
        family: 'ProximaNova,Arial,sans-serif',
        weight: '400'
    };

    Chart.defaults.plugins.title = {
        align: 'center',
        color: colors.neutral900,
        font: {
            size: 16,
            family: 'ProximaNova,Arial,sans-serif',
            weight: '600'
        },
        fullSize: false,
        padding: 16,
        position: 'top',
        text: '',
        display: false
    };
    Chart.defaults.scale.grid.display = false;

    // Legend
    Chart.defaults.plugins.legend.position = 'bottom';
    Chart.defaults.plugins.legend.labels.usePointStyle = true;
    Chart.defaults.plugins.legend.labels.boxWidth = 6;
    Chart.defaults.plugins.legend.labels.boxHeight = 6;
    Chart.defaults.plugins.legend.labels.boxPadding = 4;
    Chart.defaults.plugins.legend.labels.padding = 16;
    Chart.defaults.plugins.legend.labels.font = {
        size: 14,
        family: 'ProximaNova,Arial,sans-serif',
        weight: '400'
    };

    // Tooltip
    Chart.defaults.plugins.tooltip.mode = 'index';
    Chart.defaults.plugins.tooltip.caretPadding = 4;
    Chart.defaults.plugins.tooltip.usePointStyle = true;
    Chart.defaults.plugins.tooltip.boxWidth = 6;
    Chart.defaults.plugins.tooltip.boxHeight = 6;
    Chart.defaults.plugins.tooltip.boxPadding = 6;
    Chart.defaults.plugins.tooltip.cornerRadius = 2;
    Chart.defaults.plugins.tooltip.displayColors = true;
    Chart.defaults.plugins.tooltip.backgroundColor = colors.neutral900;
    Chart.defaults.plugins.tooltip.padding = 12;
    Chart.defaults.plugins.tooltip.titleFont = {
        size: 14,
        family: 'ProximaNova,Arial,sans-serif',
        weight: '600'
    };
    Chart.defaults.plugins.tooltip.titleMarginBottom = 12;
    Chart.defaults.plugins.tooltip.bodySpacing = 4;
    Chart.defaults.plugins.tooltip.bodyFont = {
        size: 14,
        family: 'ProximaNova,Arial,sans-serif',
        weight: '400'
    };
    Chart.defaults.plugins.tooltip.footerFont = {
        size: 16,
        family: 'ProximaNova,Arial,sans-serif',
        weight: '600'
    };
    Chart.defaults.plugins.tooltip.footerMarginTop = 24;

    // Bar
    Chart.defaults.elements.bar.borderColor = 'transparent';
    Chart.defaults.elements.bar.borderWidth = 2;
    Chart.defaults.elements.bar.borderSkipped = 'start';
    Chart.defaults.elements.bar.borderRadius = 4;

    Chart.defaults.responsive = true;
};
