import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { colors, HBButton, HBInput, HBLink, HBLinkButton, HBText, mediaQueries } from '@honeybook/hbui';
import HBIcon from '@honeybook/icons/src/components/HBIcon/HBIcon';
import Check24 from '@honeybook/icons/src/icons/Check24';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { noop } from 'lodash';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';
import * as Yup from 'yup';
import {
    CREATE_CUSTOMER_TOKEN,
    GET_CUSTOMER_PHONE_NUMBER,
    GET_EXISTING_CUSTOMER_TOKEN,
    SEND_2FA_CODE
} from 'components/two-factor/queries';
import {
    BusinessCustomer,
    CreateCustomerTokenMutation,
    CreateCustomerTokenMutationVariables,
    CreateTokenVerificationMutation,
    CreateTokenVerificationMutationVariables,
    CustomerToken,
    CustomerTokenPurpose,
    CustomerTypeEnum,
    GetCustomerPhoneNumberQuery,
    GetCustomerPhoneNumberQueryVariables,
    GetExistingCustomerTokenQuery,
    GetExistingCustomerTokenQueryVariables,
    IndividualCustomer,
    UnitVerificationTokenChannel
} from '__generated__/graphql';
import LocalizedText from 'components/LocalizedText';
import { LocaleNS } from 'lib/client/locale-ns';
import { DashboardEvents } from 'lib/client/services/mixpanel/mixpanel-events';
import useAnalytics from 'lib/client/services/mixpanel/useAnalytics';
import getFingerprint from 'lib/fingerprint';
import { FontWeights } from 'styles/fonts';
import { formatInput } from 'lib/form-service';

export enum TwoFactorAuthAnalyticsSource {
    TransferMoney = 'transfer_money',
    TransferMoneyWithRecipient = 'transfer_money_with_recipient',
    CardActions = 'card_actions',
    TransactionFeedEmptyState = 'transaction_feed_empty_state',
    SetupList = 'setup_list',
    RecurringTransferModal = 'recurring_transfer_modal'
}

export interface TwoFactorAuthAnalyticsData {
    source: TwoFactorAuthAnalyticsSource;
}

export interface TwoFactorAuthModalProps {
    onSuccess: (token: string) => void;
    purpose: CustomerTokenPurpose;
    ctaText?: string;
    customerId?: string;
    phoneNumber?: string;
    analyticsData: TwoFactorAuthAnalyticsData;
}

type OTPInputProps = {
    otp: string;
};

const OTPInputField: FC<OTPInputProps & FieldProps> = ({ field, form: {}, ...props }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = formatInput({
            value: event.target.value,
            inputType: 'otp'
        });
        field.onChange(event);
    };

    return <OTPInput placeholder="000000" aria-labelledby={field.name} {...field} {...props} testId="otp-input" onChange={handleChange} />;
};

const TwoFactorAuth = ({ onSuccess, purpose, ctaText, customerId, phoneNumber, analyticsData }: TwoFactorAuthModalProps) => {
    const { t } = useTranslation(['twoFactor', 'common']);
    const { track } = useAnalytics();
    const translationsOptions = { ns: 'twoFactor' };
    const [serverError, setServerError] = useState('');
    const [serverErrorDescription, setServerErrorDescription] = useState('');
    const [isOtpValid, setIsOtpValid] = useState(false);

    const { data: session } = useSession();
    customerId = (customerId ?? session?.customerId) as string;
    const [verificationToken, setVerificationToken] = useState('');
    const [getVerificationToken] = useMutation<CreateTokenVerificationMutation, CreateTokenVerificationMutationVariables>(SEND_2FA_CODE, {
        onError: () => {
            setServerError(t('errors.otp.notSent', translationsOptions));
            setServerErrorDescription(t('errors.otp.checkNumber', translationsOptions));
        }
    });

    const resetErrors = () => {
        setServerError('');
        setServerErrorDescription('');
    };

    const [createCustomerToken] = useMutation<CreateCustomerTokenMutation, CreateCustomerTokenMutationVariables>(CREATE_CUSTOMER_TOKEN, {
        onError: () => {
            setServerError(t('errors.otp.wrong', translationsOptions));
        }
    });

    const sendVerificationToken = useCallback(
        async (channel: UnitVerificationTokenChannel) => {
            resetErrors();
            const { data } = await getVerificationToken({
                variables: {
                    customerId: customerId as string,
                    channel
                }
            });
            if (data?.createTokenVerification) {
                setVerificationToken(data.createTokenVerification);
            }
        },
        [customerId, getVerificationToken, setVerificationToken]
    );

    const { data: phoneData, loading: isLoadingPhoneNumber } = useQuery<GetCustomerPhoneNumberQuery, GetCustomerPhoneNumberQueryVariables>(
        GET_CUSTOMER_PHONE_NUMBER,
        {
            variables: { customerId }
        }
    );

    useEffect(() => {
        sendVerificationToken(UnitVerificationTokenChannel.Sms);
    }, [sendVerificationToken]);

    useEffectOnce(() => {
        track({
            eventName: DashboardEvents.authFormLoaded,
            eventType: 'load',
            properties: analyticsData
        });
    });

    const onCodeSubmit = async (formValues: OTPInputProps) => {
        track({
            eventName: DashboardEvents.authSubmitted,
            eventType: 'click',
            properties: analyticsData
        });
        const { otp: verificationCode } = formValues;
        const fingerprint = session?.user?.fingerprint ?? (await getFingerprint());
        resetErrors();
        const { data, errors } = await createCustomerToken({
            variables: {
                customerId: customerId as string,
                verificationToken,
                verificationCode,
                purpose,
                fingerprint
            }
        });

        if (!errors) {
            const token = data?.createCustomerToken?.token as string;
            setIsOtpValid(true);
            onSuccess(token);
        }
    };

    const OTPSchema = Yup.object().shape({
        otp: Yup.string()
            .required(t('errors.otp.invalid', translationsOptions))
            .matches(/^\d{6}$/, t('errors.otp.invalid', translationsOptions))
    });

    const getMaskedPhoneNumber = (customer?: GetCustomerPhoneNumberQuery['customer'] | null) => {
        let number = '';
        if (phoneNumber) {
            number = phoneNumber;
        } else if (customer?.type == CustomerTypeEnum.Individual) {
            number = (customer as IndividualCustomer).attributes.phone.number;
        } else if (customer?.type == CustomerTypeEnum.Business) {
            number = (customer as BusinessCustomer).attributes.contact.phone.number;
        }

        return number.slice(-4);
    };

    const onResend = () => sendVerificationToken(UnitVerificationTokenChannel.Sms);
    const onPhoneCall = () => sendVerificationToken(UnitVerificationTokenChannel.Call);

    return (
        <Formik
            initialValues={{
                otp: ''
            }}
            validationSchema={OTPSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onCodeSubmit}
        >
            {({ isSubmitting, errors }) => (
                <Container data-testid={'two-factor-auth'}>
                    <Title>{isSubmitting ? t('form.verifying', translationsOptions) : t('form.title', translationsOptions)}</Title>
                    <Description>
                        <LocalizedText
                            copyKey="form.description"
                            namespace={LocaleNS.twoFactor}
                            copyArgs={{
                                phoneNumberLast4: isLoadingPhoneNumber ? 'xxxx' : getMaskedPhoneNumber(phoneData?.customer)
                            }}
                        />
                    </Description>
                    <StyledForm>
                        <InputContainer>
                            <StyledField
                                type="string"
                                id="otp"
                                name="otp"
                                style={{
                                    background: !!serverError || !!errors.otp ? colors.red50_15 : colors.neutral100
                                }}
                                disabled={isSubmitting}
                                component={OTPInputField}
                            />
                            {isOtpValid && (
                                <StyledIcon color={colors.blue20}>
                                    <Check24 />
                                </StyledIcon>
                            )}
                        </InputContainer>
                        {serverError && (
                            <Container>
                                <StyledError>{serverError}</StyledError>
                            </Container>
                        )}
                        {errors.otp && !serverError && (
                            <Container>
                                <ErrorMessage name="otp">{msg => <StyledError>{msg}</StyledError>}</ErrorMessage>
                            </Container>
                        )}
                        {serverErrorDescription && (
                            <Container data-testid="otp-server-error">
                                <ErrorDescription>{serverErrorDescription}</ErrorDescription>
                            </Container>
                        )}
                        <Help error={!!serverError || !!serverErrorDescription || !!errors.otp}>
                            <div>
                                <LocalizedText copyKey="form.help.noCode" namespace={LocaleNS.twoFactor} />
                                <StyledLinkButton testIdName="resend-link" size="sm" onClick={onResend}>
                                    <LocalizedText copyKey="form.help.resend" namespace={LocaleNS.twoFactor} />
                                </StyledLinkButton>
                                <LocalizedText copyKey="form.help.or" namespace={LocaleNS.twoFactor} />
                                <StyledLinkButton testIdName="phone-call-link" size="sm" onClick={onPhoneCall}>
                                    <LocalizedText copyKey="form.help.phoneCall" namespace={LocaleNS.twoFactor} />
                                </StyledLinkButton>
                            </div>
                            <div>
                                <LocalizedText copyKey="form.help.lostAccess" namespace={LocaleNS.twoFactor} />
                                <StyledLink
                                    noUnderline
                                    isNewTab
                                    testIdName="contact-support-link"
                                    textType={'body14'}
                                    href="https://help.honeybook.com/en/articles/5872659-2-step-verification-login"
                                    target="_blank"
                                >
                                    <LocalizedText copyKey="form.help.contact" namespace={LocaleNS.twoFactor} />
                                </StyledLink>
                            </div>
                        </Help>

                        <SubmitButton loading={isSubmitting || isOtpValid}>{ctaText ?? t('continue', { ns: 'common' })}</SubmitButton>
                    </StyledForm>
                </Container>
            )}
        </Formik>
    );
};

export default TwoFactorAuth;

export type ExistingTokenResponse = {
    token: CustomerToken['token'];
    hasToken: boolean;
};

export function useGetExistingCustomerToken(purpose: CustomerTokenPurpose) {
    const [getExistingCustomerToken] = useLazyQuery<GetExistingCustomerTokenQuery, GetExistingCustomerTokenQueryVariables>(
        GET_EXISTING_CUSTOMER_TOKEN
    );

    return async (): Promise<ExistingTokenResponse> => {
        const { data } = await getExistingCustomerToken({
            variables: {
                purpose
            },
            fetchPolicy: 'no-cache'
        });

        const { isMasked, token } = data?.getExistingCustomerToken || ({} as CustomerToken);

        return {
            hasToken: !!token || isMasked,
            token: token ?? null
        };
    };
}

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const InputContainer = styled(Container)`
    flex-direction: row;
    justify-content: center;
`;

const Title = styled(HBText).attrs({
    type: 'display24'
})`
    font-weight: ${FontWeights.Bold};
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    color: ${colors.textPrimary};
    margin-bottom: 20px;
`;

const Description = styled(HBText).attrs({
    type: 'body16'
})`
    font-weight: ${FontWeights.Normal};
    font-size: 16px;
    line-height: 22px;
    color: ${colors.neutral900};
    margin-bottom: 32px;
    text-align: center;
    white-space: pre-line;
`;

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    height: inherit;
`;

const StyledField = styled(Field)<{ hasError: boolean }>`
    && {
        background: ${({ hasError }) => (hasError ? colors.red50_15 : colors.neutral100)};
    }
`;

const OTPInput = styled(HBInput).attrs({
    type: 'text',
    id: 'otp-input',
    css: {},
    maxLength: 6
})`
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: ${FontWeights.Normal};
    font-size: 24px;
    line-height: 32px;
    height: 54px;

    && {
        padding: 11px 16px;
        width: 124px;
    }
`;

const StyledIcon = styled(HBIcon)`
    margin-left: 5px;
    margin-right: -25px;
`;

const SubmitButton = styled(HBButton).attrs({
    type: 'submit',
    onClick: noop,
    testId: 'two-factor-auth-submit'
})`
    align-self: center;
    width: 100%;
    ${mediaQueries.mediumUp} {
        width: unset;
    }
`;

const Help = styled.div<{ error: boolean }>`
    margin: ${({ error }) => (error ? '16px' : '32px')} 0 32px 0;
    font-weight: ${FontWeights.Normal};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${colors.neutral700};
`;
const StyledLinkButton = styled(HBLinkButton).attrs({
    size: 'sm',
    textType: 'secondary'
})`
    height: 14px;
    font-weight: 400;
`;

const StyledLink = styled(HBLink)`
    color: ${colors.blue20};
    &:hover {
        color: ${colors.blue25};
    }
`;

const StyledError = styled(HBText).attrs({
    type: 'Error2',
    tag: 'span'
})`
    margin-top: 4px;
`;

const ErrorDescription = styled(HBText).attrs({
    type: 'Error1',
    tag: 'span'
})`
    margin-block-start: 13px;
`;
