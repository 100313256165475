type LogLevel = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';
type TFinanceEnv = 'development' | 'test' | 'e2e' | 'production' | 'staging';

const nodeEnv = process.env.NODE_ENV || 'development';
const unitCiToken = process.env.UNIT_CI_TOKEN || '';
const unitUserId = process.env.UNIT_USER_ID || '';
const unitApplicationTokenDuration = process.env.UNIT_APPLICATION_TOKEN_DURATION || '0';
const isTestEnv = process.env.NODE_ENV === 'test';
const unitToken = (isTestEnv ? process.env.UNIT_CI_TOKEN : process.env.UNIT_TOKEN) || '';
const verbose = process.env.VERBOSE === 'true';
const isProductionEnv = process.env.NODE_ENV === 'production';
const isDevelopmentEnv = process.env.NODE_ENV === 'development';
const financeEnv: TFinanceEnv = (process.env.FINANCE_ENV as TFinanceEnv) || 'development';
const isFinanceProd = financeEnv === 'production';
const dataSourceCacheTTL = Number(process.env.DATA_SOURCE_CACHE_TTL_SECONDS || 15) * 60 * 1000;
const flitterCacheTTL = Number(process.env.FLITTER_CACHE_TTL_MINUTES || 60) * 60 * 1000;
const hbFinanceAppToken = String(process.env.HB_FINANCE_APP_TOKEN);
const authDisabled = isDevelopmentEnv && process.env.SKIP_DEV_AUTH === 'true';
const locale = process.env.LOCALE || 'en';
const redisUrl = process.env.REDIS_URL || 'redis://127.0.0.1:6379';
const redisDbNumber = process.env.REDIS_DB_NUMBER || 0;
const unitTokenTTL = process.env.UNIT_TOKEN_TTL ? parseInt(process.env.UNIT_TOKEN_TTL) : 1800;
const unitTokenExaminationInterval = 300000;
const unitWebhookToken = process.env.UNIT_WEBHOOK_TOKEN || 'secret';
const unitWebhookCacheTTL = Number(process.env.UNIT_WEBHOOK_CACHE_TTL) || 1800;
const isCodefreshEnv = process.env.IS_CODEFRESH_ENV || false;
const ssmPath = process.env.SSM_PATH || `/hb-finance/dev`;
const awsRegion = process.env.AWS_REGION || 'us-east-1';
const statsigServerApiKey = String(process.env.STATSIG_SERVER_API_KEY);
const appRootPath = __dirname.split('/.next')[0];
const logLevel: LogLevel = (process.env.LOG_LEVEL as LogLevel) || (isDevelopmentEnv ? 'info' : 'debug');
const twoFactorAuthExpirationMilliseconds = process.env.TWO_FACTOR_AUTH_EXPIRATION_MILLISECONDS || 120000;
const isCI = process.env.IS_CI === 'true';
const hbBaseUrl = process.env.HB_BASE_URL || 'http://localhost:8000';
const flitterDataBaseUrl = process.env.FLITTER_DATA_BASE_URL || 'http://localhost:8083';
const flitterDataToken = process.env.FLITTER_DATA_BASE_TOKEN || 'secret';
const slackBotToken = String(process.env.SLACK_BOT_TOKEN);
const slackAppToken = String(process.env.SLACK_APP_TOKEN);
const paymentAlertsSlackChannel = process.env.PAYMENT_ALERTS_SLACK_CHANNEL || 'sandbox';
const riskMitigationSlackChannel = process.env.RISK_MITIGATION_SLACK_CHANNEL || 'sandbox';
const hbSignatureHeader = 'x-hb-signature';
const maxPayoutAmount = Number(process.env.MAX_PAYOUT_AMOUNT) || 5000000;
const maxRefundAmount = Number(process.env.MAX_REFUND_AMOUNT) || 5000000;
const isFullProduction = isProductionEnv && isFinanceProd;
const categorizationLinkTTL = 86400; // 24h
const postmarkServerToken = process.env.POSTMARK_SERVER_TOKEN || '';
const gqlRateLimitPeriodSeconds = Number(process.env.GQL_RATE_LIMIT_PERIOD_SECONDS) || 60;
const gqlRateLimitMaxRequests = Number(process.env.GQL_RATE_LIMIT_MAX_REQUESTS) || 100;
const twoFaRateLimitPeriodSeconds = Number(process.env.TWO_FA_RATE_LIMIT_PERIOD_SECONDS) || 60;
const twoFaRateLimitMaxRequests = Number(process.env.TWO_FA_RATE_LIMIT_MAX_REQUESTS) || 3;
const segmentWriteKey = process.env.SEGMENT_WRITE_KEY;
const enrichUnitEventEnabled = process.env.ENRICH_UNIT_EVENT_ENABLED === 'true';
const defaultStreamBatchSize = Number(process.env.DEFAULT_STREAM_BATCH_SIZE) || 64;
const oneTimeRiskCalculationThreshold = Number(process.env.ONE_TIME_RISK_CALCULATION_THRESHOLD) || 8000;
const weeklyRiskCalculationThreshold = Number(process.env.WEEKLY_RISK_CALCULATION_THRESHOLD) || 2000;
const monthlyRiskCalculationThreshold = Number(process.env.MONTHLY_RISK_CALCULATION_THRESHOLD) || 8000;
const counterpartyTransferNotificationsThreshold = Number(process.env.COUNTERPARTY_TRANSFER_NOTIFICATIONS_THRESHOLD) || 0;
const counterpartyTransfersWebhook = process.env.COUNTERPARTY_TRANSFERS_WEBHOOK == '1';
const maxAllowedCounterparties = Number(process.env.MAX_ALLOWED_COUNTERPARTIES) || 50;
const isApolloStudioEnabled = isFullProduction ? false : isDevelopmentEnv || process.env.ENABLE_APOLLO_STUDIO === 'true';
const authorizationConnectToProjectExpirationDays = Number(process.env.AUTHORIZATION_CONNECT_TO_PROJECT_EXPIRATION_DAYS) || 7;
const temporalUrl = process.env.TEMPORAL_HOST || 'localhost:7233';
const temporalPlaidGeneralQueue = process.env.PLAID_QUEUE || 'plaid-queue';
const appInstance = process.env.HB_APP_INSTANCE || 'local';
const temporalNamespace = process.env.TEMPORAL_NAMESPACE || `${appInstance}-finance`;
const shouldRunTemporal = process.env.RUN_TEMPORAL === 'true';
const temporalCloudPublicKeyPath = process.env.TEMPORAL_CLOUD_PUBLIC_KEY_PATH || '';
const temporalCloudPrivateKeyPath = process.env.TEMPORAL_CLOUD_PRIVATE_KEY_PATH || '';

// This is a strong admin token with more priviliges to do specific administrative actions
// 18/05/2023
// - customers-read, customers-write
// - accounts-read, accounts-write
// - cards-read, cards-write
const unitAdminToken = process.env.UNIT_ADMIN_TOKEN || unitToken || '';
const intercomApiURL = process.env.INTERCOM_API_URL;
const intercomToken = process.env.INTERCOM_API_KEY;
const admin = {
    notionKey: process.env.NOTION_KEY
};

const appConfig = {
    nodeEnv,
    unitCiToken,
    unitToken,
    unitUserId,
    unitApplicationTokenDuration,
    verbose,
    isTestEnv,
    isProductionEnv,
    isFullProduction,
    isDevelopmentEnv,
    dataSourceCacheTTL,
    authDisabled,
    isAuthDisabled: () => {
        if (authDisabled && isProductionEnv) {
            throw new Error('authDisabled in production?!');
        }
        return authDisabled;
    },
    locale,
    redisUrl,
    redisDbNumber,
    unitTokenTTL,
    unitWebhookToken,
    unitWebhookCacheTTL,
    isCodefreshEnv,
    ssmPath,
    awsRegion,
    statsigServerApiKey,
    appRootPath,
    logLevel,
    hbFinanceAppToken,
    twoFactorAuthExpirationMilliseconds,
    isCI,
    financeEnv,
    isFinanceProd,
    hbBaseUrl,
    flitterDataBaseUrl,
    flitterCacheTTL,
    flitterDataToken,
    unitTokenExaminationInterval,
    slackBotToken,
    slackAppToken,
    paymentAlertsSlackChannel,
    riskMitigationSlackChannel,
    hbSignatureHeader,
    maxPayoutAmount,
    maxRefundAmount,
    categorizationLinkTTL,
    postmarkServerToken,
    gqlRateLimitPeriodSeconds,
    gqlRateLimitMaxRequests,
    twoFaRateLimitPeriodSeconds,
    twoFaRateLimitMaxRequests,
    segmentWriteKey,
    admin,
    enrichUnitEventEnabled,
    defaultStreamBatchSize,
    unitAdminToken,
    moneyTransferRiskThreshold: {
        oneTime: oneTimeRiskCalculationThreshold,
        weekly: weeklyRiskCalculationThreshold,
        monthly: monthlyRiskCalculationThreshold
    },
    counterpartyTransfersWebhook,
    maxAllowedCounterparties,
    counterpartyTransferNotificationsThreshold,
    isApolloStudioEnabled,
    authorizationConnectToProjectExpirationDays,
    intercomApiURL,
    intercomToken,
    temporal: {
        shouldRun: shouldRunTemporal,
        namespace: temporalNamespace,
        url: temporalUrl,
        plaidQueue: temporalPlaidGeneralQueue,
        tls: {
            publicKeyPath: temporalCloudPublicKeyPath,
            privateKeyPath: temporalCloudPrivateKeyPath
        }
    }
};

export default appConfig;
