import { css } from 'styled-components';

const CDN_PREFIX = 'https://d25purrcgqtc5w.cloudfront.net/dist/fonts';

export enum Fonts {
    ProximaNova = 'proximanova',
    Montserrat = 'Montserrat'
}

export enum FontWeights {
    Light = 300,
    Normal = 400,
    SemiBold = 600,
    Bold = 700,
    ExtraBold = 800
}

export const ProximaNovaLightFontFace = {
    'font-family': Fonts.ProximaNova,
    src: `url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_1_0.woff2') format('woff2')`,
    'font-weight': FontWeights.Light
};

export const ProximaNovaSemiBoldFontFace = {
    'font-family': Fonts.ProximaNova,
    src: `url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_2_0.woff2') format('woff2')`,
    'font-weight': FontWeights.SemiBold
};

export const ProximaNovaNormalFontFace = {
    'font-family': Fonts.ProximaNova,
    src: `url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_2_0.woff2') format('woff2')`,
    'font-weight': FontWeights.Normal
};

export const fontFaces = css`
    @font-face {
        font-family: '${Fonts.ProximaNova}';
        src: ${ProximaNovaLightFontFace.src};
        font-weight: ${FontWeights.Light};
    }

    @font-face {
        font-family: '${Fonts.ProximaNova}';
        src: url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_4_0.woff2') format('woff2');
        font-weight: ${FontWeights.Normal};
    }

    @font-face {
        font-family: '${Fonts.ProximaNova}';
        src: url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_2_0.woff2') format('woff2');
        font-weight: ${FontWeights.SemiBold};
        font-style: normal;
        font-stretch: normal;
    }

    @font-face {
        font-family: '${Fonts.ProximaNova}';
        src: url('${CDN_PREFIX}/${Fonts.ProximaNova}/302D42_5_0.woff2') format('woff2');
        font-weight: ${FontWeights.Bold};
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: ${Fonts.Montserrat};
        src: url('${CDN_PREFIX}/${Fonts.Montserrat}/${Fonts.Montserrat}-semibold-webfont.woff2') format('woff2');
        font-weight: ${FontWeights.SemiBold};
        font-style: normal;
        font-display: fallback;
    }

    @font-face {
        font-family: ${Fonts.Montserrat};
        src: url('${CDN_PREFIX}/${Fonts.Montserrat}/${Fonts.Montserrat}-extrabold-webfont.woff2') format('woff2');
        font-weight: ${FontWeights.ExtraBold};
        font-style: normal;
        font-display: fallback;
    }
`;
