import { gql } from '@apollo/client';

export const SEND_2FA_CODE = gql`
    mutation CreateTokenVerification($customerId: ID, $channel: UnitVerificationTokenChannel!) {
        createTokenVerification(customerId: $customerId, channel: $channel)
    }
`;

export const CREATE_CUSTOMER_TOKEN = gql`
    mutation CreateCustomerToken($customerId: ID, $verificationToken: ID!, $verificationCode: ID!, $purpose: CustomerTokenPurpose!, $fingerprint: ID) {
        createCustomerToken(
            customerId: $customerId
            verificationToken: $verificationToken
            verificationCode: $verificationCode
            purpose: $purpose
            fingerprint: $fingerprint
        ) {
            isMasked
            token
        }
    }
`;

export const GET_CUSTOMER_PHONE_NUMBER = gql`
    query GetCustomerPhoneNumber($customerId: ID) {
        customer(id: $customerId) {
            type
            ... on IndividualCustomer {
                attributes {
                    phone {
                        number
                    }
                }
            }
            ... on BusinessCustomer {
                attributes {
                    contact {
                        phone {
                            number
                        }
                    }
                }
            }
        }
    }
`;

export const GET_EXISTING_CUSTOMER_TOKEN = gql`
    query GetExistingCustomerToken($purpose: CustomerTokenPurpose!) {
        getExistingCustomerToken(purpose: $purpose) {
            isMasked
            token
        }
    }
`;
