export enum ItemType {
    Dropdown = 'dropdown',
    Input = 'input',
    Percentage = 'percentage',
    Text = 'text'
}

export const inputTypes = [ItemType.Dropdown, ItemType.Input, ItemType.Percentage];

export interface IOption {
    label: string;
    value: string;
}

export interface IDropdownProps {
    field: string;
    type: ItemType.Dropdown;
    options: IOption[];
}

export interface IInputProps {
    field: string;
    type: ItemType.Input | ItemType.Percentage;
    fieldType?: string;
    last4?: string;
}

export interface ITextItemProps {
    type: 'text';
    text: string;
    textArea: string;
}

export type TInput = IDropdownProps | IInputProps;

export type TSectionItem = TInput | ITextItemProps;

export interface IFormValues {
    [key: string]: string | boolean;
}

export interface ISubmitFormValues {
    [key: string]: string;
}

export interface TRepeatableSectionValues {
    [key: string]: IFormValues[];
}

export interface IFormSection {
    name: string;
    note?: string;
    items: TSectionItem[];
    layout: string[][];
    optional?: boolean;
    icon: 'person' | 'bank' | 'briefcase' | 'home' | 'buildings';
}

export interface IRepeatableSection {
    section: IFormSection;
    sectionKey: string;
    addMoreText: string;
}

export interface ICheckboxItem {
    text: string;
    textType: 'secondary' | 'primary';
    hrefs?: string[];
    checkboxError: string;
}

export interface IFormPage {
    name: string;
    title: string;
    subTitle: string;
    sections: IFormSection[];
    repeatableSection?: IRepeatableSection;
    submitText: string;
    checkbox?: ICheckboxItem;
}

export interface IIntialPageValues {
    [key: string]: string | boolean | IFormValues[];
}

export interface ILast4digits {
    accountLast4?: string;
    routingLast4?: string;
}

export interface PrefillInfo {
    values: IFormValues;
    last4: ILast4digits;
}
