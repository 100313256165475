import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import {CustomerToken, CustomerTokenPurpose} from '__generated__/graphql';
import { useGetExistingCustomerToken } from 'components/two-factor/TwoFactorAuth';
import appConfig from 'config/appConfig';

interface IContextProps {
    setToken: React.Dispatch<React.SetStateAction<CustomerToken['token']>>;
    setPassed2FA: React.Dispatch<React.SetStateAction<boolean>>;
    passed2FA: boolean;
    token: CustomerToken['token'];
}

const TwoFactorAuthContext = createContext<IContextProps>({} as IContextProps);
TwoFactorAuthContext.displayName = 'TwoFactorAuthProvider';

export const useTwoFactorAuth = () => {
    return useContext(TwoFactorAuthContext);
};

const TwoFactorAuthProvider = ({ children }: { children: ReactNode }) => {
    const [passed2FA, setPassed2FA] = useState(false);
    const [token, setToken] = useState<CustomerToken['token']>('');

    const getExistingCustomerToken = useGetExistingCustomerToken(CustomerTokenPurpose.FullPrivileges);

    const checkExistingCustomerToken = useCallback(async () => {
        const { token: currentToken } = await getExistingCustomerToken();

        setPassed2FA(!!currentToken);
        setToken(currentToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkExistingCustomerToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(() => {
        checkExistingCustomerToken();
    }, appConfig.unitTokenExaminationInterval);

    const value = {
        passed2FA,
        setPassed2FA,
        setToken,
        token
    };
    return <TwoFactorAuthContext.Provider value={value}>{children}</TwoFactorAuthContext.Provider>;
};

export default TwoFactorAuthProvider;
