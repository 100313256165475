import { FullStory as FullStoryClient, init, isInitialized } from '@fullstory/browser';
import { useSession } from 'next-auth/react';
import getConfig from 'next/config';
import { useEffect } from 'react';
import { isProductionEnv } from 'lib/client/utils';
import logger from '@finance/shared/dist/utils/logger';

export default function FullStory() {
    const session = useSession();
    const {
        publicRuntimeConfig: { fullStoryOrgId }
    } = getConfig();

    useEffect(() => {
        const devMode = !isProductionEnv();
        if (!isInitialized() && session.data) {
            init({ orgId: fullStoryOrgId, devMode, recordCrossDomainIFrames: true }, ({ sessionUrl }) => {
                logger.info({ sessionUrl, devMode }, `full story started`);
                FullStoryClient('setIdentity', {
                    uid: session.data.user._id,
                    properties: {
                        email: session.data.user.email,
                        unitCustomerId: session.data?.customerId
                    }
                });
            });
        }
    }, [session, fullStoryOrgId]);

    return null;
}
