import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { AuthPages } from 'lib/types/types';

export async function copyToClipBoard({ elementId }: { elementId: string }) {
    const copyText = document.getElementById(elementId)?.textContent as string;
    return navigator.clipboard.writeText(copyText);
}

export function reloadSession() {
    const event = new Event('visibilitychange');
    document.dispatchEvent(event);
}

export function useSignOut() {
    const router = useRouter();
    return () => router.push(AuthPages.signOut);
}

export const formatPhoneNumber = (value: string): string => {
    // Return the original value if it's empty or null
    if (!value) return value;

    // Remove all non-digit characters from the input
    const digits = value.replace(/\D/g, '');
    const length = digits.length;

    // Return the cleaned value if no digits
    if (length === 0) return '';

    // Format based on the length of the digits
    if (length <= 3) return `(${digits}`;
    if (length <= 6) return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

export function isProductionEnv() {
    const {
        publicRuntimeConfig: { env }
    } = getConfig();
    return env === 'production';
}

export function isStagingEnv() {
    const {
        publicRuntimeConfig: { env }
    } = getConfig();
    return env === 'staging';
}

export function useServerPropsReload() {
    const router = useRouter();
    return () => router.replace(router.asPath);
}

export function fileToBase64(file: File): Promise<string> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            const base64String = reader.result as string;
            const commaSeparatorIdx = base64String.indexOf(',') + 1;
            const encodedFileContent = base64String.slice(commaSeparatorIdx);
            resolve(encodedFileContent);
        };
        reader.onerror = error => {
            reject(error);
        };
        reader.readAsDataURL(file?.slice(0, file.size, file.type));
    });
}

export function generateYearsBetween(startYear?: number, endYear?: number) {
    startYear = startYear ?? new Date().getFullYear() - 100;
    const endDate = endYear ?? new Date().getFullYear();
    const years = [];

    for (let i = startYear; i <= endDate; i++) {
        years.push(startYear);
        startYear++;
    }
    return years;
}

export function transformPascalCaseToSnakeCase(pascalCaseString: string) {
    return pascalCaseString
        .split(/(?=[A-Z])/)
        .join('_')
        .toLowerCase();
}
