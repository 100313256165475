import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

interface IProps {
    copyKey: string;
    namespace?: string;
    copyArgs?: {
        [key: string]: string | number | undefined;
    };
}

const LocalizedText = ({ copyKey, namespace = 'common', copyArgs }: IProps) => {
    const { t } = useTranslation(['common', namespace]);

    return (
        <LocalizationContainer className="ltext" data-loc-key={copyKey} data-loc-ns={namespace} data-loc-args={copyArgs}>
            {t(copyKey, { ns: namespace, ...copyArgs })}
        </LocalizationContainer>
    );
};

export default LocalizedText;

const LocalizationContainer = styled.span`
    position: relative;
`;
