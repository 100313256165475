import { useEffect, useState, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

// ClientOnly component is used to render the children only on the client side.
// Read more about it here: https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export default function ClientOnly({ children, ...delegated }: Props) {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return null;
    }

    return <section {...delegated}>{children}</section>;
}
