import Script from 'next/script';

declare global {
    interface Window {
        VGSShow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}

const VGSProvider = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Script src="https://js.verygoodvault.com/vgs-show/1.5/ACh8JJTM42LYxwe2wfGQxwj5.js" />;
};

export default VGSProvider;
