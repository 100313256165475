import { colors, HBButton, HBText } from '@honeybook/hbui';
import { withTranslation } from 'next-i18next';
import { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import ImageWrapper from 'components/ImageWrapper';
import LocalizedText from 'components/LocalizedText';
import { trackError } from 'lib/error.utils';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        trackError({
            error,
            message: `[ErrorBoundary]: caught unhandled error`,
            context: errorInfo
        });
    }

    render() {
        const { children } = this.props;
        if (this.state.hasError) {
            return (
                <Container>
                    <InnerContainer>
                        <ImageWrapper src={'/finance/404_2x'} width={200} height={190} />
                        <HBText type="Display3DarkExtrabold">
                            <LocalizedText copyKey="error-boundary.title" />
                        </HBText>
                        <Subtitle type="Body1DarkPrimary">
                            <LocalizedText copyKey="error-boundary.subtitle" />
                        </Subtitle>
                        <ReloadButton onClick={() => this.setState({ hasError: false })} size="lg" testIdName="reload">
                            <LocalizedText copyKey="cta.refresh" />
                        </ReloadButton>
                    </InnerContainer>
                </Container>
            );
        } else {
            return children;
        }
    }
}

const Subtitle = styled(HBText)`
    margin-block-start: 18px;
`;

const ReloadButton = styled(HBButton)`
    margin-block-start: 42px;
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 650px;
`;

export default withTranslation('common')(ErrorBoundary);
