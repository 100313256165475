import Fingerprint2 from '@fingerprintjs/fingerprintjs';
import logger from '@finance/shared/dist/utils/logger';

async function getFingerprint(): Promise<string> {
    let fingerprint = '';
    logger.debug('getting fingerprint');
    try {
        const fpPromise = await Fingerprint2.load();
        const visitorPromise = await fpPromise.get();

        if (window.name === '') {
            window.name = `t${Math.round(Math.random() * 1000)}`;
        }
        fingerprint = `${visitorPromise.visitorId}-${window.name}`;
    } catch (e) {
        logger.error(`failed getting fingreprint`, e);
    }

    return fingerprint;
}

export default getFingerprint;
