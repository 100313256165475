import Script from 'next/script';
import getConfig from 'next/config';

const UnitComponentsProvider = () => {
    const {
        publicRuntimeConfig: { env }
    } = getConfig();

    const unitWebComponentsSrc = env === 'production' ? 'https://ui.unit.co/components.js' : 'https://ui.s.unit.sh/components.js';

    return <Script async src={unitWebComponentsSrc} />;
};

export default UnitComponentsProvider;
