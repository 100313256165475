import * as Yup from 'yup';
import { ObjectShape } from 'yup';
import { isInput } from 'lib/form-service';
import { IFormPage, IFormSection, IInputProps, ItemType } from 'components/application-form/form-pages/form-types';

export enum errorMessages {
    Empty = 'empty',
    Phone = 'phone',
    Email = 'email',
    ZipCode = 'zip-code',
    SSN = 'ssn',
    RoutingNumber = 'routing-number',
    BankAccountNumber = 'bank-account-number',
    ShortDate = 'short-date',
    LongDate = 'long-date',
    WrongEnd = 'wrong-end',
    Percentage = 'percentage'
}

// taken from https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
const dateRegexWithDays = /^\d\d\/\d\d\/\d\d\d\d$/;

export const fieldLengths = {
    routing_ssn: { length: 9 },
    zip_code: { length: 5 },
    bank_account_number: { min: 6, max: 17 },
    otp: { length: 6 }
};

const dateValidationWithDays = (date: string) => {
    if (!date) {
        return true;
    }
    if (!dateRegexWithDays.test(date)) {
        return false;
    }
    const mm = Number(date.substring(0, 2));
    if (mm < 1 || mm > 12) {
        return false;
    }
    const dd = Number(date.substring(3, 5));
    if (dd < 1 || dd > 31) {
        return false;
    }
    const yyyy = Number(date.substring(6, 10));
    const currYear = new Date().getFullYear();
    if (yyyy > currYear) {
        return false;
    }

    const eighteenYears = 567648000000;
    return Date.now() - Number(new Date(date)) > eighteenYears;
};

const range75 = (value: string) => {
    const percentage = Number(value);
    return percentage >= 1 && percentage <= 75;
};

const range100 = (value: string) => {
    const percentage = Number(value);
    return percentage >= 1 && percentage <= 100;
};

const yupValidations: ObjectShape = {
    routing_number: Yup.string().length(fieldLengths.routing_ssn.length, errorMessages.RoutingNumber),
    ssn: Yup.string().length(fieldLengths.routing_ssn.length, errorMessages.SSN),
    zip_code: Yup.string().length(fieldLengths.zip_code.length, errorMessages.ZipCode),
    bank_account_number: Yup.string()
        .min(fieldLengths.bank_account_number.min, errorMessages.BankAccountNumber)
        .max(fieldLengths.bank_account_number.max, errorMessages.BankAccountNumber),
    phone_number: Yup.string().matches(phoneRegex, errorMessages.Phone),
    long_date: Yup.string().test('long_date', errorMessages.LongDate, value => dateValidationWithDays(value as string)),
    range75: Yup.string().test('range75', errorMessages.Percentage, value => range75(value as string)),
    range100: Yup.string().test('range100', errorMessages.Percentage, value => range100(value as string)),
    email: Yup.string().email(errorMessages.Email)
};

export const createPageValidationSchema = ({ page, sectionNumber = 0 }: { page: IFormPage; sectionNumber?: number }) => {
    const pageShape: ObjectShape = {};
    const generateSchemaFromSection = ({ section, shape }: { section: IFormSection; shape: ObjectShape }) => {
        section.items.filter(item => {
            if (isInput(item)) {
                const input = item as IInputProps;
                let validation: Yup.StringSchema = Yup.string();

                if (item.type !== ItemType.Dropdown) {
                    validation = (yupValidations[input.fieldType as string] as Yup.StringSchema) || validation;
                }

                validation = section.optional
                    ? (validation
                          .notRequired()
                          .nullable()
                          .transform(value => value || null) as Yup.StringSchema)
                    : (validation.required(errorMessages.Empty) as Yup.StringSchema);

                if (input.last4) {
                    const { last4 } = input;
                    validation = validation.test(`last_4_digits_${last4}`, errorMessages.WrongEnd, value => {
                        return value ? (value || '').endsWith(last4 as string) : true;
                    });
                }
                shape[input.field] = validation;
            }
        });
    };
    page.sections.forEach(section => generateSchemaFromSection({ section, shape: pageShape }));

    if (page.repeatableSection && sectionNumber) {
        const repeatSectionShapes = [];
        const sectionShape: ObjectShape = {};
        generateSchemaFromSection({
            section: page.repeatableSection?.section as IFormSection,
            shape: sectionShape
        });

        for (let i = 0; i < sectionNumber; i++) {
            repeatSectionShapes.push(Yup.object(sectionShape));
        }
        pageShape[page.repeatableSection.sectionKey] = Yup.array(...repeatSectionShapes);
    }

    if (page.checkbox) {
        pageShape[page.checkbox.text] = Yup.bool().oneOf([true], page.checkbox.checkboxError);
    }

    return Yup.object().shape(pageShape);
};
