import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { HBToast, ToastProps } from '@honeybook/hbui';
import ClientOnly from 'components/ClientOnly';

interface IContextProps {
    addToast: (toast: ToastProps) => void;
    removeToast: (id: string) => void;
    removeAllToasts: () => void;
}

const ToastContext = createContext<IContextProps>({} as IContextProps);

export const useToast = () => {
    return useContext(ToastContext);
};

export const ToastProvider = ({ children }: { children: ReactNode }) => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);

    const removeAllToasts = useCallback(() => {
        setToasts([]);
    }, []);

    const removeToast = useCallback((id: string) => {
        setToasts(prevState => prevState.filter(toast => toast.id !== id));
    }, []);

    const addToast = useCallback((toast: ToastProps) => {
        const id = toast.id || String(Math.random());
        setToasts(prevState => [...prevState, { ...toast, id }]);
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, removeToast, removeAllToasts }}>
            <ClientOnly>
                <HBToast toasts={toasts} removeItem={removeToast} />
            </ClientOnly>
            {children}
        </ToastContext.Provider>
    );
};
