import { Statsig, StatsigProvider } from 'statsig-react';
import StatsigContext, { UpdateUserFunc } from 'statsig-react/dist/StatsigContext';
import type { AsyncStorage, DeviceInfo, ExpoConstants, ExpoDevice, NativeModules, Platform, UUID } from 'statsig-js';
import { _SDKPackageInfo } from 'statsig-js';
import getConfig from 'next/config';
import { useContext, useEffect } from 'react';
import featureFlags from '.gates.json';
import { useSession } from 'next-auth/react';

declare type Props = {
    children: React.ReactNode | React.ReactNode[];
    setUser?: UpdateUserFunc;
    initializingComponent?: React.ReactNode | React.ReactNode[];
    mountKey?: string;
    shutdownOnUnmount?: boolean;
    _reactNativeDependencies?: {
        SDKPackageInfo: _SDKPackageInfo;
        AsyncStorage: AsyncStorage | null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        AppState: any | null;
        NativeModules: NativeModules | null;
        Platform: Platform | null;
        RNDevice: DeviceInfo | null;
        Constants: ExpoConstants | null;
        ExpoDevice: ExpoDevice | null;
        ReactNativeUUID: UUID | null;
    };
};

const StatsigProviderWrapper = ({
    children,
    setUser,
    initializingComponent,
    mountKey,
    shutdownOnUnmount,
    _reactNativeDependencies
}: Props): JSX.Element => {
    const { data: session } = useSession();
    const statsigContext = useContext(StatsigContext);

    useEffect(() => {
        Statsig.setInitializeValues(featureFlags);
    }, [statsigContext.initialized]);

    const {
        publicRuntimeConfig: { statsigClientApiKey, env }
    } = getConfig();

    return (
        <StatsigProvider
            sdkKey={statsigClientApiKey}
            user={{
                custom: {
                    account_id: session?.user?.accountId
                },
                email: session?.user?.email,
                customIDs: {
                    account_id: session?.user?.accountId || ''
                }
            }}
            setUser={setUser}
            options={{
                environment: {
                    tier: env
                }
            }}
            waitForInitialization
            initializingComponent={initializingComponent}
            mountKey={mountKey}
            shutdownOnUnmount={shutdownOnUnmount}
            _reactNativeDependencies={_reactNativeDependencies}
        >
            {session && children}
        </StatsigProvider>
    );
};

export { StatsigProviderWrapper as StatsigProvider };
